const startProjectContainers = $('.contact-form');
const startProjectTitles = $('.contact-form-title');
const startProjectInputs = $('.contact-form-input');
const startProjectError = $('.contact-form-error');
const startProjectContainersCount = startProjectContainers.length;
const startProjectBackArrow = $('.contact-form-arrow-back');
const startProjectNextArrow = $('.contact-form-arrow-next');
const startProjectPaperPlane = $('.contact-form-paper-plane-container');
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line

let startProjectCurrentStep;

const sendRequest = () => {
  return $.ajax({
    url: 'https://fjv1x4crn3.execute-api.us-east-1.amazonaws.com/prod/subscribers',
    method: 'POST',
    data: JSON.stringify({
      fullName: $('.name-input').val(),
      country: $('.country-input').val(),
      email: $('.email-input').val(),
      message: $('.message-input').val()
    }),
    contentType: 'application/json',
    dataType: 'json'
  });
};

const showElem = (elem) => {
  $(elem).removeClass('hidden');
};
const hideElem = (elem) => {
  $(elem).addClass('hidden');
};

const validations = [
  () => ({ valid: true }), // this is for the click here button
  (val) => ({ valid: val && val.length > 3, error: 'This field is required.' }),
  (val) => ({ valid: val && val.length > 3, error: 'This field is required.' }),
  (val) => ({ valid: val && val.match(emailRegex), error: 'Invalid email address.' }),
  () => ({ valid: true }),
  (val) => ({ valid: val && val.length > 6, error: 'Specify your contact reason.' })
];

const validateStep = (index, value) => validations[index](value);

const validate = (index) => {
  const validation = validateStep(index, $(startProjectInputs[index]).val()); // eslint-disable-line
  validation.error = validation.valid ? '' : validation.error;
  return validation;
};

const handleNext = (index) => {
  const validation = validate(index);
  if (validation.error) {
    showError(index, validation.error);
  } else {
    hideStep(index);
    showStep(index + 1);
    startProjectCurrentStep = index + 1;
  }
};

const handleBack = (index) => {
  if (index === 1) {
    hideElem(startProjectBackArrow);
    hideElem(startProjectNextArrow);
  }
  hideStep(index);
  showStep(index - 1);
  startProjectCurrentStep = index - 1;
};

const hideError = (index) => {
  startProjectError.text('');
  $(startProjectInputs[index]).removeClass('error');
};

const showError = (index, error) => {
  startProjectError.text(error);
  $(startProjectInputs[index]).addClass('error');
};

const hideStep = (index) => {
  hideElem(startProjectContainers[index]);
  hideElem(startProjectTitles[index]);
  hideElem(startProjectInputs[index]);
  hideError(index);
  $(startProjectInputs[index]).unbind('keypress');
};

const showStep = (index) => {
  showElem(startProjectContainers[index]);
  showElem(startProjectTitles[index]);
  showElem(startProjectInputs[index]);
  $(startProjectInputs[index]).focus();

  if (index + 1 === startProjectContainersCount) {
    hideElem(startProjectBackArrow);
    hideElem(startProjectPaperPlane);
    sendRequest();
  } else if (index + 1 === startProjectContainersCount - 1) {
    hideElem(startProjectNextArrow);
    showElem(startProjectPaperPlane);
  } else if (index) {
    showElem(startProjectNextArrow);
    hideElem(startProjectPaperPlane);
    $(startProjectInputs[index]).keypress((ev) => {
      const enterKey = 13;
      if (ev.which === enterKey) {
        handleNext(index);
      } else {
        hideError(index);
      }
    });
  }
};

$(startProjectInputs[0]).click(() => {
  hideStep(0);
  startProjectCurrentStep = 1;
  showStep(startProjectCurrentStep);
  showElem(startProjectBackArrow);
  showElem(startProjectNextArrow);
});
startProjectPaperPlane.click(() => handleNext(startProjectCurrentStep));
startProjectNextArrow.click(() => handleNext(startProjectCurrentStep));
startProjectBackArrow.click(() => handleBack(startProjectCurrentStep));
